import React from 'react';

const Courses = () => {
    return (
        <div>
            <h2>Courses</h2>
        </div>
    )
}

export default Courses