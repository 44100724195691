import { useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import './Dashboard.scss';
import { FaChevronDown, FaUserCircle } from 'react-icons/fa';
import logo from '../../Assets/Home Page/Logo.png';
import { useNavigate } from 'react-router-dom';
import Courses from '../../Module/Dashboard/Courses/Courses';

const Dashboard = () => {
    const [activeSection, setActiveSection] = useState('courses');
    const [showMenu, setShowMenu] = useState(false);
    const [showRightContent, setShowRightContent] = useState(window.innerWidth >= 1008);
    const overlayPanelRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const navigate = useNavigate()

    const showProfileMenu = (event) => {
        setShowMenu(!showMenu);
        overlayPanelRef.current.toggle(event);
    };
    const handleLogoClick = () => {
        navigate('/dashboard');
    };

    const toggleRightContent = () => {
        setShowRightContent(!showRightContent);
        setMenuOpen(!menuOpen);
    };

    window.addEventListener('resize', () => {
        setShowRightContent(window.innerWidth >= 1009);
    });

    const handleCourse = () => {
        setActiveSection('courses');
    };

    const handleChatbot = () => {
        setActiveSection('chatbot');
    };

    const handleSettings = () => {
        setActiveSection('settings');
    };

    return (
        <div className="container">
            <div className="left-bar p-0">
                <div className="logo" onClick={handleLogoClick}>
                    <img src={logo} alt="" />
                </div>
                <div className="avatar-section">
                    <FaUserCircle style={{ width: "100px", height: "100px" }} />

                    <div className="student-name">
                        Shaji.P
                    </div>
                    <div className="student-grade">
                        10th Grade
                    </div>
                </div>
                <div className="menu-items">
                    <ul>
                        <li onClick={handleCourse}>My Courses</li>
                        <li onClick={handleChatbot}>Chatbot</li>
                        <li onClick={handleSettings}>Settings</li>
                    </ul>
                </div>
            </div>

            <div className="header">
                <div className="stack-navbar">
                    <div className="stackbar-heading"><h2>Dashboard</h2></div>

                    <div className="menu-container">
                        {window.innerWidth < 1008 && (

                            <div
                                className={`menu-toggle ${showRightContent ? "active" : ""}`}
                                onClick={toggleRightContent}
                            >
                                <div className={`bar ${showRightContent ? "cross" : ""}`} ></div>
                                <div className={`bar ${showRightContent ? "cross" : ""}`}></div>
                                <div className={`bar ${showRightContent ? "cross" : ""}`}></div>
                            </div>
                        )}
                        {showRightContent && (
                            <ul className={`menu-items ${showMenu ? 'show' : ''}`}>

                                <div>
                                    <div className='profileIcon' onClick={(e) => showProfileMenu(e)}>
                                        <>
                                            <FaUserCircle />
                                            Shaji.P
                                            <FaChevronDown />
                                        </>
                                    </div>
                                    <OverlayPanel
                                        ref={overlayPanelRef}
                                        appendTo={document.body}
                                        onHide={() => setShowMenu(false)}
                                    >
                                        <ul className="profile-menu-list">

                                        </ul>
                                    </OverlayPanel>
                                </div>
                            </ul>
                        )}
                    </div>
                </div>
                <div className="menu-content-section">
                    {activeSection === 'courses' && (
                        <div>
                            <Courses />
                        </div>
                    )}
                    {activeSection === 'chatbot' && (
                        <div>
                            <h2>Chatbot</h2>
                        </div>
                    )}
                    {activeSection === 'settings' && (
                        <div>
                            <h2>Settings</h2>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;